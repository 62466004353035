.container {
  position: relative;
  height: 40px;
  width: 40px;
}

.threshold {
  position: absolute;
  margin-top: -6px;
  margin-left: -16px;
  left: 36px;
  z-index: 1;
  border-radius: 100%;
  font-size: 12px;
  min-width: 24px;
  min-height: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  font-weight: 700;
  background-color: var(--color-secondary-light);
}
