/* This file is generated from the MUI theme colors. Do not edit directly. */

:root {
  --color-text-primary: #121312;
  --color-text-secondary: #454549;
  --color-text-disabled: #454549;
  --color-primary-dark: #4a00b2;
  --color-primary-main: #7919ff;
  --color-primary-light: #9a52ff;
  --color-secondary-dark: #454549;
  --color-secondary-main: #121312;
  --color-secondary-light: #b480ff;
  --color-secondary-background: #f3eefb;
  --color-border-main: #454549;
  --color-border-light: #a2a2a4;
  --color-border-background: #e0e0e1;
  --color-error-dark: #80003f;
  --color-error-main: #ff1980;
  --color-error-light: #ff80bf;
  --color-error-background: #ffb2d8;
  --color-success-dark: #1e8a50;
  --color-success-main: #2dcf78;
  --color-success-light: #5beb9d;
  --color-success-background: #84f0b6;
  --color-info-dark: #004d80;
  --color-info-main: #199fff;
  --color-info-light: #4bb7ff;
  --color-info-background: #80ccff;
  --color-warning-dark: #802b00;
  --color-warning-main: #ff5319;
  --color-warning-light: #ff8652;
  --color-warning-background: #ffaa80;
  --color-background-default: #f5f5f7;
  --color-background-main: #f5f5f7;
  --color-background-paper: #ffffff;
  --color-background-light: #f3eefb;
  --color-backdrop-main: #454549;
  --color-logo-main: #232325;
  --color-logo-background: #e0e0e1;
  --color-static-main: #232325;
  --space-1: 8px;
  --space-2: 16px;
  --space-3: 24px;
  --space-4: 32px;
  --space-5: 40px;
  --space-6: 48px;
  --space-7: 56px;
  --space-8: 64px;
  --space-9: 72px;
  --space-10: 80px;
  --space-11: 88px;
  --space-12: 96px;
}

[data-theme='dark'] {
  --color-text-primary: #ffffff;
  --color-text-secondary: #a2a2a4;
  --color-text-disabled: #a2a2a4;
  --color-primary-dark: #7919ff;
  --color-primary-main: #9a52ff;
  --color-primary-light: #b480ff;
  --color-secondary-dark: #a2a2a4;
  --color-secondary-main: #ffffff;
  --color-secondary-light: #b480ff;
  --color-secondary-background: #303033;
  --color-border-main: #a2a2a4;
  --color-border-light: #454549;
  --color-border-background: #343437;
  --color-error-dark: #ff80bf;
  --color-error-main: #ff1980;
  --color-error-light: #80003f;
  --color-error-background: #4c0026;
  --color-success-dark: #5beb9d;
  --color-success-main: #2dcf78;
  --color-success-light: #1e8a50;
  --color-success-background: #0a2e1b;
  --color-info-dark: #4bb7ff;
  --color-info-main: #199fff;
  --color-info-light: #004d80;
  --color-info-background: #002e4c;
  --color-warning-dark: #ff8652;
  --color-warning-main: #ff5319;
  --color-warning-light: #802b00;
  --color-warning-background: #4c1a00;
  --color-background-default: #111112;
  --color-background-main: #111112;
  --color-background-paper: #232325;
  --color-background-light: #211b2a;
  --color-backdrop-main: #454549;
  --color-logo-main: #ffffff;
  --color-logo-background: #232325;
  --color-static-main: #121312;
}

/* The same as above for the brief moment before JS loads */
@media (prefers-color-scheme: dark) {
  :root:not([data-theme='light']) {
    --color-text-primary: #ffffff;
    --color-text-secondary: #a2a2a4;
    --color-text-disabled: #a2a2a4;
    --color-primary-dark: #7919ff;
    --color-primary-main: #9a52ff;
    --color-primary-light: #b480ff;
    --color-secondary-dark: #a2a2a4;
    --color-secondary-main: #ffffff;
    --color-secondary-light: #b480ff;
    --color-secondary-background: #303033;
    --color-border-main: #a2a2a4;
    --color-border-light: #454549;
    --color-border-background: #343437;
    --color-error-dark: #ff80bf;
    --color-error-main: #ff1980;
    --color-error-light: #80003f;
    --color-error-background: #4c0026;
    --color-success-dark: #5beb9d;
    --color-success-main: #2dcf78;
    --color-success-light: #1e8a50;
    --color-success-background: #0a2e1b;
    --color-info-dark: #4bb7ff;
    --color-info-main: #199fff;
    --color-info-light: #004d80;
    --color-info-background: #002e4c;
    --color-warning-dark: #ff8652;
    --color-warning-main: #ff5319;
    --color-warning-light: #802b00;
    --color-warning-background: #4c1a00;
    --color-background-default: #111112;
    --color-background-main: #111112;
    --color-background-paper: #232325;
    --color-background-light: #211b2a;
    --color-backdrop-main: #454549;
    --color-logo-main: #ffffff;
    --color-logo-background: #232325;
    --color-static-main: #121312;
  }
}
