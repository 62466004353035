.container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  line-height: 1.4;
}

.avatar {
  flex-shrink: 0;
}

.resizeAvatar,
.resizeAvatar * {
  width: 2.3em !important;
  height: 2.3em !important;
}

.addressRow {
  display: flex;
  align-items: center;
  gap: 0.25em;
  white-space: nowrap;
}

.nameRow {
  overflow: hidden;
}

.mobileAddress {
  display: none;
}

@media (max-width: 600px) {
  .mobileAddress {
    display: inline-block;
  }

  .desktopAddress {
    display: none;
  }
}
