.container,
.drawer {
  height: 100vh;
  padding-top: var(--header-height);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color-background-paper);
}

.container {
  width: 230px;
  border-right: 1px solid var(--color-border-light);
}

.scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.drawer {
  width: 400px;
  max-width: 90vw;
}

.drawer {
  text-align: center;
  padding: var(--header-height) 0 0 0;
  border-right: 1px solid var(--color-border-light);
}

.noSafeHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 100px;
}

.drawerButton {
  position: absolute !important;
  z-index: 2;
  right: 0;
  transform: translateX(50%);
  margin-top: 54px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  background-color: var(--color-background-main);
}

.drawerButton:hover {
  background-color: var(--color-secondary-background);
}

.drawerButton svg {
  transform: translateX(-25%);
}

@media (max-width: 900px) {
  .container {
    padding-top: var(--header-height);
  }

  .drawer {
    max-width: 90vw;
  }

  .drawerButton {
    width: 60px;
    height: 60px;
    margin-top: 44px;
  }
}
