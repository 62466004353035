.container {
  padding: var(--space-2) var(--space-1);
}

.info {
  padding: 0 var(--space-1);
}

.safe {
  display: flex;
  gap: 12px;
  text-align: left;
  align-items: center;
}

.iconButtons {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
}

.iconButton {
  border-radius: 4px;
  padding: 6px;
  background-color: var(--color-background-main);
  width: 32px;
  height: 32px;
}

.iconButton:hover {
  background-color: var(--color-secondary-background);
}

.address {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}
